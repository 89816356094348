/**
 * Hand-converted from ./sln/common/GeoRiot.Billing/Feature.cs
 *
 * @todo - get this from the server somehow (or auto-convert)
 */
export const enum Feature {
  TrackingPixels = 'Tracking Pixels',
  UnsupportedTrackingPixels = 'Unsupported Tracking Pixels',
  CustomDomain = 'Custom Domain',
  ProductSuggestions = 'ProductSuggestions',
  ClientRequestedProductMatching = 'ClientRequestedProductMatching',
  StraightRedirect = 'Straight Redirect',

  AdvancedTargeting = 'Advanced Targeting',
  AdvancedTargetingLanguage = 'Advanced Targeting Language',
  AdvancedTargetingPlace = 'Advanced Targeting Place',
  AdvancedTargetingBrowser = 'AdvancedTargetingBrowser',
  AdvancedTargetingOsVersion = 'AdvancedTargetingOsVersion',
  AdvancedTargetingSystemPreset = 'AdvancedTargetingSystemPreset',
  AdvancedTargetingUserPreset = 'AdvancedTargetingUserPreset',

  LinkCloning = 'Link Cloning',
  ApiAccess = 'API Access',

  ReportWizard = 'ReportingWizard',
  GeniusLinkUtmReporting = 'Utm',
  UtmAnalytics = 'Utm Analytics',
  CommissionReporting = 'Commission Reporting',
  CoversionReporting = 'Conversion Reporting',
  TeamAccount = 'Team Accounts',
  QrCode = 'QrCode',
  Referral = 'Referral Program',
  Interstitial = 'Interstitial',
  MultipleEndpoints = 'MultipleEndpoints',
  CustomNamespaces = 'CustomNamespace',
  LocalizationTesting = 'LocalizationTesting',
  // Used in GeoRiot.Service.
  RouteCheckerDoNotSaveClicks = 'RouteCheckerDoNotSaveClicks',
  /// <summary>
  /// Used in Service API to not save clicks when calling through translation extended proxy
  /// </summary>
  CrossStoreTargetingDoNotSaveClicks = 'CrossStoreTargetingDoNotSaveClicks',
  CrossStoreTargetingAllowAllTsids = 'CrossStoreTargetingAllowAllTsids',
  RouteCheckerAllowAllTsids = 'RouteCheckerAllowAllTsids',
  LinkHealth = 'LinkHealth',
  PerformanceReportEmails = 'PerformanceReportEmails',
  ChoicePageAllowScripting = 'ChoicePageAllowScripting',
  ChoicePageThemes = 'ChoicePageThemes',
  ChoicePageCustomStoreButtons = 'ChoicePageCustomStoreButtons',

  GDPRPixeling = 'GDPRPixeling',
  HubspotOptIn = 'HubspotOptIn',
  Newsletters = 'Newsletters',
  AdvancedTargetTimeZones = 'AdvancedTargetTimeZones',

  // Affiliate = {
  //   Aggregator: 'AffiliateAggregator',
  //   Walmart: 'AffiliateWalmart',
  //   BandH: 'AffiliateBandH',
  //   Kobo: 'AffiliateKobo'
  // }

  /**
   * @note
   * Front-end defined features - not server-fed
   */
  ReportsPage = 'ReportsPage',
  ToolsPage = 'ToolsPage',
  AccountPage = 'AccountPage',
  BillingPage = 'BillingPage'
}

export interface IFeature {
  /// <summary>
  /// Our unique key name for the feature
  /// </summary>
  Name: string

  /// <summary>
  /// Is the feature active or not for the given account plan/plan template.  If false, should probably be not shown in the UI, etc.
  /// </summary>
  Active: boolean

  /// <summary>
  /// The number of instances of this feature that are included in the plan
  /// </summary>
  NumberIncludedInPlan?: number

  /// <summary>
  /// Max number of times this feature can be used by an account
  /// i.e., how many domains can they add to an account, regardless of overage pricing (probably infinite...)
  /// </summary>
  Limit?: number

  /// <summary>
  /// If no instances are included in plan, or if the user goes uses more than that, how much to charge for each
  /// </summary>
  PriceInCents?: number

  /// <summary>
  /// What schedule do we charge for overages on, daily, monthly, one time ever, ...
  /// </summary>
  // [BsonElement("recurrency")]
  // [BsonRepresentation(BsonType.String)]
  // public PriceRecurrency Recurrency { set; get; }

  // [BsonElement("preferences")]
  // [BsonIgnoreIfDefault]
  // public FeaturePreferences Preferences { get; set; }
}
